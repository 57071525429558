import React from "react";

const IconWrapper = ({ children }) => {
  return (
    <div className="cursor-pointer [&>svg]:w-[20px] [&>svg]:h-[20px] [&>svg]:fill-black bg-orange bg-opacity-0 p-2 rounded-full hover:bg-opacity-100 [&:hover>svg]:fill-black transition-all [&>svg]:transition-all">
      {children}
    </div>
  );
};

export const InstagramIcon = () => {
  return (
    <IconWrapper>
      <svg viewBox="0 0 512 512">
        <path d="M349.33,69.33a93.62,93.62,0,0,1,93.34,93.34V349.33a93.62,93.62,0,0,1-93.34,93.34H162.67a93.62,93.62,0,0,1-93.34-93.34V162.67a93.62,93.62,0,0,1,93.34-93.34H349.33m0-37.33H162.67C90.8,32,32,90.8,32,162.67V349.33C32,421.2,90.8,480,162.67,480H349.33C421.2,480,480,421.2,480,349.33V162.67C480,90.8,421.2,32,349.33,32Z" />
        <path d="M377.33,162.67a28,28,0,1,1,28-28A27.94,27.94,0,0,1,377.33,162.67Z" />
        <path d="M256,181.33A74.67,74.67,0,1,1,181.33,256,74.75,74.75,0,0,1,256,181.33M256,144A112,112,0,1,0,368,256,112,112,0,0,0,256,144Z" />
      </svg>
    </IconWrapper>
  );
};

export const WhatsAppIcon = () => {
  return (
    <IconWrapper>
      <svg viewBox="0 0 512 512">
        <path
          d="M414.73,97.1A222.14,222.14,0,0,0,256.94,32C134,32,33.92,131.58,33.87,254A220.61,220.61,0,0,0,63.65,365L32,480l118.25-30.87a223.63,223.63,0,0,0,106.6,27h.09c122.93,0,223-99.59,223.06-222A220.18,220.18,0,0,0,414.73,97.1ZM256.94,438.66h-.08a185.75,185.75,0,0,1-94.36-25.72l-6.77-4L85.56,427.26l18.73-68.09-4.41-7A183.46,183.46,0,0,1,71.53,254c0-101.73,83.21-184.5,185.48-184.5A185,185,0,0,1,442.34,254.14C442.3,355.88,359.13,438.66,256.94,438.66ZM358.63,300.47c-5.57-2.78-33-16.2-38.08-18.05s-8.83-2.78-12.54,2.78-14.4,18-17.65,21.75-6.5,4.16-12.07,1.38-23.54-8.63-44.83-27.53c-16.57-14.71-27.75-32.87-31-38.42s-.35-8.56,2.44-11.32c2.51-2.49,5.57-6.48,8.36-9.72s3.72-5.56,5.57-9.26.93-6.94-.46-9.71-12.54-30.08-17.18-41.19c-4.53-10.82-9.12-9.35-12.54-9.52-3.25-.16-7-.2-10.69-.2a20.53,20.53,0,0,0-14.86,6.94c-5.11,5.56-19.51,19-19.51,46.28s20,53.68,22.76,57.38,39.3,59.73,95.21,83.76a323.11,323.11,0,0,0,31.78,11.68c13.35,4.22,25.5,3.63,35.1,2.2,10.71-1.59,33-13.42,37.63-26.38s4.64-24.06,3.25-26.37S364.21,303.24,358.63,300.47Z"
          style={{ fillRule: "evenodd" }}
        />
      </svg>
    </IconWrapper>
  );
};

export const TikTokIcon = () => {
  return (
    <IconWrapper>
      <svg viewBox="0 0 512 512">
        <path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z" />
      </svg>
    </IconWrapper>
  );
};

export const MailIcon = () => {
  return (
    <IconWrapper>
      <svg viewBox="0 0 512 512">
        <path d="M424,80H88a56.06,56.06,0,0,0-56,56V376a56.06,56.06,0,0,0,56,56H424a56.06,56.06,0,0,0,56-56V136A56.06,56.06,0,0,0,424,80Zm-14.18,92.63-144,112a16,16,0,0,1-19.64,0l-144-112a16,16,0,1,1,19.64-25.26L256,251.73,390.18,147.37a16,16,0,0,1,19.64,25.26Z" />
      </svg>
    </IconWrapper>
  );
};

export const FacebookIcon = () => {
  return (
    <IconWrapper>
      <svg viewBox="0 0 512 512">
        <path
          d="M480,257.35c0-123.7-100.3-224-224-224s-224,100.3-224,224c0,111.8,81.9,204.47,189,221.29V322.12H164.11V257.35H221V208c0-56.13,33.45-87.16,84.61-87.16,24.51,0,50.15,4.38,50.15,4.38v55.13H327.5c-27.81,0-36.51,17.26-36.51,35v42h62.12l-9.92,64.77H291V478.66C398.1,461.85,480,369.18,480,257.35Z"
          fillRule="evenodd"
        />
      </svg>
    </IconWrapper>
  );
};

export const YoutubeIcon = () => {
  return (
    <IconWrapper>
      <svg viewBox="0 0 512 512">
        <path d="M508.64,148.79c0-45-33.1-81.2-74-81.2C379.24,65,322.74,64,265,64H247c-57.6,0-114.2,1-169.6,3.6-40.8,0-73.9,36.4-73.9,81.4C1,184.59-.06,220.19,0,255.79q-.15,53.4,3.4,106.9c0,45,33.1,81.5,73.9,81.5,58.2,2.7,117.9,3.9,178.6,3.8q91.2.3,178.6-3.8c40.9,0,74-36.5,74-81.5,2.4-35.7,3.5-71.3,3.4-107Q512.24,202.29,508.64,148.79ZM207,353.89V157.39l145,98.2Z" />
      </svg>
    </IconWrapper>
  );
};

export const SoundcloudIcon = () => {
  return (
    <IconWrapper>
      <svg viewBox="0 0 512 512">
        <path d="M5.8,278a2.11,2.11,0,0,0-2,2L0,308.64,3.74,336.8a2.12,2.12,0,0,0,2.05,2,2.14,2.14,0,0,0,2-2h0l4.44-28.17L7.83,280a2.14,2.14,0,0,0-2-2Z" />
        <path d="M26.85,262.32a2.13,2.13,0,0,0-4.26,0l-5,46.32,5,45.3a2.13,2.13,0,0,0,4.26,0l5.73-45.31-5.73-46.32Z" />
        <path d="M106.17,219.59a4,4,0,0,0-3.87,3.87l-4,85.22,4,55.08a3.88,3.88,0,0,0,7.75,0v0l4.53-55.08-4.53-85.22A4,4,0,0,0,106.17,219.59Z" />
        <path d="M65.12,249.21a3.09,3.09,0,0,0-3,3L57.6,308.66l4.51,54.63a3,3,0,0,0,6,0l5.13-54.63-5.13-56.48A3.1,3.1,0,0,0,65.12,249.21Z" />
        <path d="M147.88,367.6a4.83,4.83,0,0,0,4.75-4.74l3.93-54.15-3.93-113.46a4.75,4.75,0,0,0-9.5,0l-3.49,113.45,3.49,54.17A4.81,4.81,0,0,0,147.88,367.6Z" />
        <path d="M233.28,367.85a6.6,6.6,0,0,0,6.5-6.52v0l2.74-52.6-2.74-131a6.5,6.5,0,1,0-13,0l-2.45,131c0,.08,2.45,52.67,2.45,52.67A6.59,6.59,0,0,0,233.28,367.85Z" />
        <path d="M190.26,367.65a5.67,5.67,0,0,0,5.62-5.64v0l3.34-53.33-3.34-114.28a5.63,5.63,0,1,0-11.25,0l-3,114.29,3,53.32a5.66,5.66,0,0,0,5.63,5.6Z" />
        <path d="M85.56,367.15A3.53,3.53,0,0,0,89,363.74l4.83-55.09L89,256.25a3.44,3.44,0,0,0-6.88,0l-4.26,52.38,4.26,55.08A3.5,3.5,0,0,0,85.56,367.15Z" />
        <path d="M44.84,364.13a2.67,2.67,0,0,0,2.57-2.52l5.43-53-5.42-55a2.57,2.57,0,0,0-5.14,0l-4.78,55,4.78,53a2.62,2.62,0,0,0,2.56,2.53Z" />
        <path d="M211.69,192.53a6.1,6.1,0,0,0-6.07,6.09l-2.71,110.11,2.71,53a6.07,6.07,0,0,0,12.13,0v0l3-53-3-110.13a6.1,6.1,0,0,0-6.06-6.07Z" />
        <path d="M127,367.71a4.41,4.41,0,0,0,4.31-4.3l4.23-54.71L131.26,204a4.32,4.32,0,0,0-8.63,0L118.89,308.7l3.75,54.73A4.38,4.38,0,0,0,127,367.71Z" />
        <path d="M174.17,362.54v0l3.63-53.8-3.63-117.28a5.19,5.19,0,1,0-10.37,0l-3.23,117.28,3.23,53.83a5.18,5.18,0,0,0,10.36,0v0Z" />
        <path d="M449,241.1A62.42,62.42,0,0,0,424.67,246c-5-57.18-52.61-102-110.66-102a111.92,111.92,0,0,0-40.28,7.58c-4.75,1.85-6,3.76-6.06,7.46V360.4a7.66,7.66,0,0,0,6.8,7.5c.16,0,173.44.11,174.56.11,34.78,0,63-28.41,63-63.45s-28.2-63.46-63-63.46Z" />
        <path d="M254.79,158.87a7,7,0,0,0-6.94,7L245,308.75l2.85,51.87a6.94,6.94,0,1,0,13.87-.06v.06l3.09-51.87-3.09-142.93a7,7,0,0,0-6.93-6.95Z" />
      </svg>
    </IconWrapper>
  );
};

const SocialLinks = (props) => {
  const { links } = props;

  return (
    <div className="flex mt-6 md:mt-0 justify-center flex-wrap">
      {links?.map((link) => {
        return (
          <a
            key={link.url}
            href={link.url}
            target={link.url.startsWith("mailto:") ? "" : "_blank"}
            rel="noopener noreferrer"
            title={link.title}
            aria-label={`Link para ${link.title}`}
          >
            <div
              dangerouslySetInnerHTML={{ __html: link.iconSvg.iconSvg }}
              className="cursor-pointer [&>svg]:w-[20px] [&>svg]:h-[20px] [&>svg]:fill-black bg-orange bg-opacity-0 p-2 rounded-full hover:bg-opacity-100 [&:hover>svg]:fill-white transition-all [&>svg]:transition-all"
            />
          </a>
        );
      })}
    </div>
  );
};

export default SocialLinks;
