import React from "react";

import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

const Layout = (props) => {
  const { children, data } = props || {};

  const { settings } = data || {};
  const title = settings?.edges?.[0]?.node?.title;
  const homepageId = settings?.edges?.[0]?.node.homepage.id;
  const navigation = settings?.edges?.[0]?.node?.navigation.map((i) => {
    if (i.id === homepageId) {
      return {
        ...i,
        slug: "",
      };
    }
    return i;
  });
  const copyrightCopy = settings?.edges?.[0]?.node?.copyrightCopy;
  const logoSvg = settings?.edges?.[0]?.node?.logoSvg?.logoSvg;
  const socialLinks = settings?.edges?.[0]?.node?.socialLinks;

  // Conditionally don't show top and bottom nav
  if (children.props.location.pathname === "/instabio/") {
    return (
      <div className="min-h-screen relative flex flex-col flex-1 isolate bg-white">
        {children}
      </div>
    );
  }

  return (
    <div className="min-h-screen relative flex flex-col flex-1 isolate bg-white">
      <NavBar
        title={title}
        logo={logoSvg}
        navigation={navigation}
        socialLinks={socialLinks}
      />
      <main className="relative flex-1 bg-white">{children}</main>
      <Footer
        title={title}
        logo={logoSvg}
        copyrightCopy={copyrightCopy}
        socialLinks={socialLinks}
      />
    </div>
  );
};

export default Layout;
