import React, { useEffect, useState } from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import Social from "./Social";

const Footer = ({ title, socialLinks, logo, copyrightCopy }) => {
  const [isClient, setClient] = useState(false);

  useEffect(() => {
    setClient(true);
  }, []);

  return (
    <div className="bg-white border-t-[5px] border-orange">
      <nav className="max-w-[1400px] mx-auto px-6 md:px-8">
        <div className="pt-8 pb-2 flex flex-col md:flex-row items-center justify-between">
          <div className="flex md:min-w-0 md:flex-1">
            <h1 className="m-0 p-0 text-[0px]">
              <AniLink paintDrip hex="#060B16" to="/">
                <span className="sr-only">{title}</span>
                <div
                  className="[&>svg]:fill-orange [&>svg]:h-5 [&>svg]:md:h-6"
                  dangerouslySetInnerHTML={{ __html: logo }}
                />
              </AniLink>
            </h1>
          </div>
          <div className="-mt-1.5 md:ml-8">
            <Social links={socialLinks} />
          </div>
        </div>
      </nav>
      <div className="max-w-[1400px] mx-auto px-6 md:px-8 mt-4">
        <div className="border-t-[1px] border-black border-opacity-[0.05] py-4">
          <div className="text-center md:text-left">
            <p className="text-black text-xs">
              {copyrightCopy?.replace(
                "{ano}",
                isClient ? new Date().getFullYear() : ""
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
